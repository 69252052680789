import './Canvas.css'
import * as THREE from 'three'
import { useRef, useState } from 'react'
import { Canvas, useFrame, extend} from '@react-three/fiber'
import { OrbitControls, shaderMaterial } from '@react-three/drei'

const SphereShaderMaterial = {
    wireframe: true,
    uniforms: {
      u_time: { type: "f", value: 0 }
    },
    vertexShader: `
        precision mediump float;
        uniform float u_time;
        varying float d;
        //uniform float frequency[512];
        //uniform float mDb;
        varying vec2 vUv;

        float hash(float n) { return fract(sin(n) * 1e4); }
        float hash(vec2 p) { return fract(1e4 * sin(17.0 * p.x + p.y * 0.1) * (0.1 + abs(sin(p.y * 13.0 + p.x)))); }
        
        float noise(vec3 x) {
            const vec3 step = vec3(110, 241, 171);
        
            vec3 i = floor(x);
            vec3 f = fract(x);
         
            // For performance, compute the base input to a 1D hash from the integer part of the argument and the 
            // incremental change to the 1D based on the 3D -> 1D wrapping
            float n = dot(i, step);
        
            vec3 u = f * f * (3.0 - 2.0 * f);
            return mix(mix(mix( hash(n + dot(step, vec3(0, 0, 0))), hash(n + dot(step, vec3(1, 0, 0))), u.x),
                           mix( hash(n + dot(step, vec3(0, 1, 0))), hash(n + dot(step, vec3(1, 1, 0))), u.x), u.y),
                       mix(mix( hash(n + dot(step, vec3(0, 0, 1))), hash(n + dot(step, vec3(1, 0, 1))), u.x),
                           mix( hash(n + dot(step, vec3(0, 1, 1))), hash(n + dot(step, vec3(1, 1, 1))), u.x), u.y), u.z);
        }

        float radius(vec3 p) {
            return sqrt(p.x*p.x + p.x*p.x);
        }

        void main() {
            vUv = uv;
            // d = noise(position*radius(position)*sin(u_time));
            d = noise(position*position*(1.5+sin(u_time)));
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position*d, 1.0 );
        }
    `,
    fragmentShader: `
      precision mediump float;
      varying vec2 vUv;
      varying float d;
      uniform float u_time;
  
      void main() {
        vec2 uv = vUv;
        gl_FragColor = vec4(1.*u_time);
      }
    `
  };

function Box(props) {
    // This reference gives us direct access to the THREE.Mesh object
    const ref = useRef()
    // Hold state for hovered and clicked events
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (
        ref.current.material.uniforms.u_time.value += delta
    ))
    // Return the view, these are regular Threejs elements expressed in JSX
    return (
      <mesh
        {...props}
        ref={ref}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}>
        <sphereGeometry args={[2, 64, 64]} />
        <shaderMaterial attach="material" args={[SphereShaderMaterial]} />
      </mesh>
    )
}

export default function CanvasModel() {
    return (
        <Canvas>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <Box position={[0, 0, 0]} />
            <OrbitControls />
        </Canvas>
    )
}
