import './Landing.css'
import Video from './Video'
import CanvasModel from './Canvas'
import { FaGithub, FaTwitter } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

export default function Landing() {
    return (
    <div className="container">
        <Video />
        <div id="canvas-container">
            <CanvasModel />
            </div>
            <div className='container'>
            <div>
                <h1>Full Stack Dev</h1>
            </div>
            <div className="icon-container">
                <a href="https://github.com/AnwarAliAhmad" target="_blank" rel="noopener noreferrer"> <FaGithub/> </a>
                <a href="mailto:contact@anwaraliahmad.tech" target="_blank" rel="noopener noreferrer"> <AiOutlineMail/> </a>
                <a href="https://twitter.com/AnwarAliAhmad" target="_blank" rel="noopener noreferrer"><FaTwitter/> </a>
            </div>
        </div>
    </div>
    )
}