import React, { Component } from "react";
import './Video.css'


export default class Video extends Component {
    render() {
        return (
            <div className="video-container">
                <div class='embed-container'>
                    <iframe src="https://player.vimeo.com/video/814084915?h=87311ae0bc&amp;app_id=58479&amp;background=1&amp;controls=0" 
                    muted
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
                    allowfullscreen 
                    title="One of my vid clips..."></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        );
    }
}
